import React from "react"
import { graphql } from "gatsby"
import PropTypes from 'prop-types';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Disqus } from 'gatsby-plugin-disqus';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO
        keywords={frontmatter.keywords.split(',')}
        title={frontmatter.title}
      />
	  <div className="p-10 w-full md:w-3/4 mx-auto pt-10">

		  <div className="text-xl text-gray-800 leading-normal">

        <div className="font-sans">
          <span className="text-base md:text-sm text-green-500 font-bold">&lt;</span>
          <a href="/blog/" className="text-base md:text-sm text-green-500 font-bold no-underline hover:underline">BACK TO BLOG</a>
          
          <h1 className="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">{frontmatter.title}</h1>
  <h2 className="text-sm md:text-base font-normal text-gray-600">{frontmatter.date} - {frontmatter.author}</h2>
        </div>
			    <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: html }}
          />
			</div>
      </div>
      <div className="p-10 mx-auto">
        <Disqus
          config={{
            url: 'https://www.falkonwings.com/blog/' + frontmatter.slug,
            /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
            identifier: frontmatter.slug,
            /* Replace PAGE_TITLE with the title of the page */
            title: frontmatter.title,
          }}
        />
      </div>  
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        keywords
        author
      }
    }
  }
`

Template.propTypes = {
  data: PropTypes.string
};